import { createRouter, createWebHistory } from 'vue-router';

import Login from '../views/Login.vue';
import ResetPassword from '../views/ResetPassword.vue';
import Register from '../views/Register.vue';
import RegisterEntity from '../views/RegisterEntity.vue';



import DashboardEntrepreneur from '../views/entrepreneur/Dashboard.vue';
import SurveysEntrepreneur from '../views/entrepreneur/Surveys.vue';

import DashboardAdmin from '../views/admin/Dashboard.vue';
import UsersAdmin from '../views/admin/Users.vue';
import SurveysAdmin from '../views/admin/Surveys.vue';
import PermissionsAdmin from '../views/admin/Permissions.vue';
import ProfileAdmin from '../views/admin/Profile.vue';

import PermisoDenegado from '../views/PermisoDenegado.vue';
import PaginaNoExiste from '../views/PaginaNoExiste.vue';

import NotificationsUser from '../views/user/Notifications.vue';

import axios from 'axios';
import store from '../store/index'
import entrepreneur from './entrepreneur';
import entity from './entity';
import forms from './forms';

const routes = [
  { path: '/', redirect: '/login' }, // Redirige a la vista de login por defecto
  {
    path: '/permiso-denegado',
    name: 'PermisoDenegado',
    component: PermisoDenegado,
    meta: { role: 'admin' }
  },
  {
    path: '/login',
    name: 'Login',
    component: Login,
    meta: { role: 'login' }
  },
  {
    path: '/registro',
    name: 'Register',
    component: Register,
    meta: { role: 'login' }
  },
  {
    path: '/recuperar-cotrasena',
    name: 'ResetPassword',
    component: ResetPassword,
    meta: { role: 'login' }
  },
  {
    path: '/registro-entidad',
    name: 'RegisterEntity',
    component: RegisterEntity,
    meta: { role: 'login' }
  },
  ...forms,


  {
    path: '/entidad',
    name: 'EntityEntrepreneur',
    // component: DashboardEntrepreneur,
    meta: { role: 'entity', title: 'Tablero de Datos', role_id: [4, 5], requiresAuth: true, subTitle: 'Visión general de las encuestas y resultados en tiempo real.', }
  },
  {
    path: '/entidad/encuestas',
    name: 'SurveysEntity',
    component: SurveysEntrepreneur,
    meta: { role: 'entity', title: 'Encuestas', role_id: [4, 5], requiresAuth: true, subTitle: 'Visualiza resultados y gestiona el estado de tus encuestas.', }
  },
  ...entity,
  {
    path: '/empresario',
    name: 'DashboardEntrepreneur',
    component: DashboardEntrepreneur,
    meta: { role: 'entrepreneur', title: 'Tablero de Datos', role_id: [1, 2, 6, 7, 8], requiresAuth: true, subTitle: 'Visión general de las encuestas y resultados en tiempo real.', }
  },
  ...entrepreneur,
  // {
  //   path: '/empresario/configuracion/informacion-personal',
  //   name: 'ProfileEntrepreneur',
  //   component: ProfileAdmin,
  //   meta: { role: 'account', title: 'Mi perfil', role_id: [2], requiresAuth: true, subTitle: 'Gestiona tu información personal y configura tus preferencias.', }
  // },
  
  {
    path: '/empresario/encuestas',
    name: 'SurveysEntrepreneur',
    component: SurveysEntrepreneur,
    meta: { role: 'entrepreneur', title: 'Encuestas', role_id: [1, 2, 6, 7, 8], requiresAuth: true, subTitle: 'Visualiza resultados y gestiona el estado de tus encuestas.', }
  },
  {
    path: '/empresario/notificaciones',
    name: 'NotificationsUser',
    component: NotificationsUser,
    meta: { role: 'entrepreneur', title: 'Notificaciones', role_id: [1, 2, 6, 7, 8], requiresAuth: true, subTitle: null, }
  },
  {
    path: '/administrador',
    name: 'DashboardAdmin',
    component: DashboardAdmin,
    meta: { role: 'superadmin', title: 'Tablero de Datos', role_id: [3], requiresAuth: true, subTitle: 'Visión general de las encuestas y resultados en tiempo real.', }
  },
  {
    path: '/administrador/usuarios',
    name: 'UsersAdmin',
    component: UsersAdmin,
    meta: { role: 'superadmin', title: 'Usuarios', role_id: [3], requiresAuth: true, subTitle: 'Gestión de usuarios y control de accesos en el sistema de encuestas.', }
  },
  {
    path: '/administrador/encuentas',
    name: 'SurveysAdmin',
    component: SurveysAdmin,
    meta: { role: 'superadmin', title: 'Encuestas', role_id: [3], requiresAuth: true, subTitle: 'Visualiza resultados y gestiona el estado de tus encuestas.', }
  },
  {
    path: '/administrador/roles-permisos',
    name: 'PermissionsAdmin',
    component: PermissionsAdmin,
    meta: { role: 'superadmin', title: 'Roles y Permisos', role_id: [3], requiresAuth: true, subTitle: 'Configura los roles y permisos para garantizar la seguridad y el acceso adecuado.', }
  },
  {
    path: '/administrador/perfil',
    name: 'ProfileAdmin',
    component: ProfileAdmin,
    meta: { role: 'superadmin', title: 'Mi perfil', role_id: [3], requiresAuth: true, subTitle: 'Gestiona tu información personal y configura tus preferencias.', }
  },





  {
    path: '/:pathMatch(.*)*',
    name: 'PaginaNoExiste',
    component: PaginaNoExiste,
    meta: { role: 'admin' }
  }
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});


router.beforeEach(async (to, from, next) => {
  const isAuthenticated = localStorage.getItem('token');

  if (to.meta.requiresAuth && !isAuthenticated) {
    next('/login');
  } else if (to.meta.requiresAuth) {
    try {
      let url = process.env.VUE_APP_API_URL;

      const response = await axios.get(url + '/bearer', {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`
        }
      });

      const userInfo = response.data;

      if (userInfo && userInfo.response && userInfo.response.details) {

        store.dispatch('setBusinessInfo', userInfo.response.details)

        const actividades = userInfo.response.details.find((item) => item.question_id == 504);

        if (actividades) {
          console.log(actividades.selected_options);
          
          const validar_guia = actividades.selected_options.id == 1047;

          if (validar_guia) {
            store.dispatch('setIsGuide', true)
          }


        }


      }

      if (to.meta.role_id.includes(userInfo.roles[0])) {
        store.dispatch('setUser', userInfo)


        store.dispatch('setToken', localStorage.getItem('token'))
        next();
      } else {
        next({ name: 'PermisoDenegado' })
      }
    } catch (error) {
      console.error('Error fetching user roles:', error);
      next('/login');
    }
  } else {
    next();
  }
});

// router.beforeEach(async (to, from, next) => {
//   const isAuthenticated = localStorage.getItem('token');

//   if (to.meta.requiresAuth && !isAuthenticated) {
//     next('/login');
//   } else if (to.meta.requiresAuth) {
//     try {
//       let url = process.env.VUE_APP_API_URL;

//       const response = await axios.get(url + '/bearer', {
//         headers: {
//           Authorization: `Bearer ${localStorage.getItem('token')}`
//         }
//       });

//       const userInfo = response.data;

//       if (to.meta.role_id.includes(userInfo.user.role_id)) {
//         store.dispatch('setUser', userInfo.user)


//         if (userInfo && userInfo.user && userInfo.user.role_id == 2 && userInfo.user.role_id) {
//           store.dispatch('setPropertyId', userInfo.user.property_id)
//         }

//         store.dispatch('setToken', localStorage.getItem('token'))
//         next();
//       } else {
//         next({ name: 'PermisoDenegado' })
//       }
//     } catch (error) {
//       console.error('Error fetching user roles:', error);
//       next('/login');
//     }
//   } else {
//     next();
//   }
// });

export default router;