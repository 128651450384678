<template>
  <UserExperience></UserExperience>
  <v-navigation-drawer
    v-model="drawer"
    :elevation="0"
    border="1"
    color="white"
    class="navigation-border-0"
    width="300"
  >
    <v-list>
      <v-list-item>
        <v-list-item-title class="text-h6 font-weight-bold">
          <v-img
            :cover="false"
            :src="require('@/assets/img/logo-guaviare-color.png')"
          ></v-img>
        </v-list-item-title>
      </v-list-item>
    </v-list>

    <v-list
      class="text-primary menu-admin mt-16"
      active-class="text-white bg-transparent menu-admin-active list-remove-overlay"
      density="compact"
      nav
    >
      <template v-for="(menu, index) in itemsMenu" :key="index">
        <v-list-item :to="menu.path" rounded="xl" class="overflow-hidden">
          <template v-slot:default="">
            <span class="text-body-2"
              >{{ menu.title }} {{ $router.currentRoute["fullPath"] }}
            </span>
            <div v-if="$route.path == menu.path" class="bg"></div>
            <div v-if="$route.path == menu.path" class="bg bg2"></div>
            <div v-if="$route.path == menu.path" class="bg bg3"></div>
          </template>
          <template v-slot:prepend="{ isActive }">
            <v-list-item-action end>
              <v-icon v-if="menu.isIcon">{{ menu.icon }}</v-icon>
              <v-icon v-else>
                <img :src="isActive ? menu.iconActive : menu.icon" />
              </v-icon>
            </v-list-item-action>
          </template>

          <!-- <v-list-item-title>
                        <span class="text-body-2">{{ menu.title }} {{ $router.currentRoute['fullPath'] }} </span>
   
                    </v-list-item-title> -->
          <!--  -->
        </v-list-item>
      </template>
    </v-list>

    <template v-slot:append>
      <div class="pa-2 mb-4">
        <v-btn
          prepend-icon="mdi-information-outline"
          variant="text"
          color="primary"
          rounded
          block
          class="text-capitalize mb-2 justify-start"
        >
          Información & Ayuda
        </v-btn>
        <v-btn
          @click="logout"
          prepend-icon="mdi-logout"
          variant="text"
          color="primary"
          rounded
          block
          class="text-capitalize justify-start"
        >
          Cerrar Sesión
        </v-btn>
      </div>
      <div class="pa-2 text-center text-caption"></div>
    </template>
    <v-divider vertical></v-divider>
  </v-navigation-drawer>

  <v-main class="">
    <v-card
      light
      elevation="0"
      height="100%"
      rounded
      class="pa-0"
      :color="$route.name == 'ProfileAdmin' ? '#FFF' : '#FCFCFC'"
    >
      <!-- Si el nombre del componente es ProfileAdmin se aplica un estilo al v-card-text -->
      <v-card-text height="100%" style="height: 100%">
        <div class="d-flex align-center">
          <v-app-bar-nav-icon
            class="mr-3"
            variant="text"
            @click.stop="drawer = !drawer"
          ></v-app-bar-nav-icon>
          <div>
            <span
              class="text-h4 font-weight-bold"
              :class="{ 'animate__animated animate__bounceIn': showAnimationTitle }"
            >
              {{ $route.meta.title }}
            </span>
            <br />
            <span>
              <span
                class="text-caption text-grey"
                :class="{
                  'animate__animated animate__bounceIn animate__delay-1s': showAnimationTitle,
                }"
              >
                {{ $route.meta.subTitle }}
              </span>
            </span>
            <br />
          </div>
          <v-spacer></v-spacer>
          <!-- <div
            style="width: 300px"
            class="text-subtitle-2 d-none align-center mr-0 d-md-flex"
          >
            <span class="text-right mr-2" style="width: 100%; height: 20px">
              {{ formattedDate }}
            </span>

            <v-avatar class="mr-4 bg-grey-lighten-3">
              <v-icon color=""> mdi-calendar-clock-outline </v-icon>
            </v-avatar>
          </div> -->

          <!-- <v-avatar class="mr-4 d-none d-md-flex" color="#FEBF0F">
            <v-icon color="white"> mdi-bell </v-icon>
          </v-avatar> -->

          <v-list-item class="d-none d-md-flex" lines="two" append-avatar="">
            <template v-slot:append>
              <v-avatar role="button" id="menu-activator" color="grey-lighten-1">
                <v-img
                  v-if="
                    $store &&
                    $store.state &&
                    $store.state.user &&
                    $store.state.user.profile_photo
                  "
                  :src="$store.state.user.profile_photo"
                ></v-img>
                <v-icon v-else color="white">mdi-account</v-icon>
              </v-avatar>
            </template>

            <v-list-item-title>
              {{ $store.state.user.name }}
            </v-list-item-title>
            <v-list-item-subtitle>Administrador</v-list-item-subtitle>
          </v-list-item>
        </div>

        <v-menu
    transition="slide-y-transition"
    activator="#menu-activator"
    bottom
    content-class="my-menu"
  >
    <v-card rounded="lg" width="250">
      <v-list class="" density="compact">
        <v-list-item :to="item.to" v-for="(item, i) in itemsMenuProfile" :key="i">
          <template v-slot:prepend>
            <v-icon>{{ item.icon }}</v-icon>
          </template>
          <v-list-item-title>{{ item.title }}</v-list-item-title>
        </v-list-item>
      </v-list>
    </v-card>
  </v-menu>

        <div
          class="text-body-2"
          :class="{ 'animate__animated animate__fadeIn': showAnimationTitle }"
          style="height: 100%"
        >
          <router-view />
        </div>
      </v-card-text>
    </v-card>
  </v-main>

  <!-- <v-navigation-drawer v-model="drawer" :elevation="0" border="0" color="primary">

        <v-list>
            <v-list-item>

        

                <v-list-item-title class="text-h6 font-weight-bold">
                    <v-img :cover="false"
                    :src="require('@/assets/img/logo-guaviare-white.png')"></v-img>
                </v-list-item-title>
            </v-list-item>
        </v-list>



        <v-list class="text-white menu-admin mt-16"
            active-class="text-white bg-secondary menu-admin-active" density="compact" nav>

            <template v-for="(menu, index) in itemsMenu" :key="index">

                <v-list-item :to="menu.path" rounded="xl">

                    <template v-slot:prepend="{ isActive }">
                        <v-list-item-action end>
                            <v-icon v-if="menu.isIcon">{{ menu.icon }}</v-icon>
                            <v-icon v-else>
                                <img :src="isActive ? menu.iconActive : menu.icon">
                            </v-icon>
                        </v-list-item-action>
                    </template>

                    <v-list-item-title>
                       <span class="text-body-2">{{ menu.title }}</span> 
                    </v-list-item-title>
                </v-list-item>

            </template>


        </v-list>

        <template v-slot:append>
            <div class="pa-2 mb-4">
                <v-btn @click="logout" prepend-icon="mdi-logout" variant="text" block class="text-capitalize"> Cerrar
                    Sesión </v-btn>
            </div>
            <div class="pa-2 text-center text-caption">


            </div>
        </template>

    </v-navigation-drawer>

    <v-main class="bg-primary">
        <v-card light elevation="0" height="100%" rounded class="pa-5" style="background-color: #00679F;">
            <v-card-text height="100%"
                style="background: #FFF 0% 0% no-repeat padding-box; border-radius: 20px; height: 100%;">

                <v-layout>
                    <v-app-bar :elevation="0" color="transparent">



                        <template v-slot:prepend>
                            <p>
                                <span class="text-h4 font-weight-bold">
                                    {{ $route.meta.title }}
                                </span>
                                <br>

                            </p>
                        </template>
                        <template v-slot:append>
                            <div style="width: 300px;" class="text-subtitle-2 d-flex align-center mr-0">
                                <span class="text-right mr-2" style="    width: 100%; height: 20px;">
                                    {{ formattedDate }}
                                </span>

                                <v-avatar class="mr-4 bg-grey-lighten-3">
                                    <v-icon color="">
                                        mdi-calendar-clock-outline
                                    </v-icon>
                                </v-avatar>
                            </div>
                          
                            <v-avatar class="mr-4" color="#FFE300">
                                <v-icon color="white">
                                    mdi-bell
                                </v-icon>
                            </v-avatar>


                            <v-list-item lines="two" append-avatar="https://randomuser.me/api/portraits/men/81.jpg"
                                subtitle="Empresario" title="Empresa JMC S.A."></v-list-item>
                        </template>
                    </v-app-bar>
                    <v-main class="text-body-2">
                        <div class="mx-2 mt-5">
                            <router-view />
                        </div>
                    </v-main>
                </v-layout>
            </v-card-text>

        </v-card>
    </v-main> -->
</template>

<script>
import { ref } from "vue";
import moment from "moment";
import axios from "axios";
import { useDisplay } from "vuetify";

import UserExperience from "../views/forms/includes/UserExperience.vue";

export default {
  name: "AdminLayout",
  components: {
    UserExperience,
  },
  setup() {
    const { mdAndDown } = useDisplay();
    const formattedDate = ref("");

    const getFormattedDate = () => {
      const date = moment(); // Obtiene la fecha y hora actual
      formattedDate.value = date.locale("es").format("D MMMM YYYY");
      // formattedDate.value = date.locale('es').format('hh:mm:ss A [de] D MMMM YYYY');
    };

    setInterval(getFormattedDate, 1000);

    return {
      isMdOrLower: mdAndDown,
      formattedDate,
    };
  },
  data: () => ({
    itemsMenuProfile: [
        {
          title: "Ir a mi perfil",
          to: "/administrador/configuracion/informacion-personal",
          icon: "mdi-account",
        },
      ],
    drawer: null,
    itemsMenu: [
      {
        path: "/administrador",
        title: "Tablero de Datos",
        isIcon: true,
        icon: "mdi-view-dashboard-outline",
      },
      {
        path: "/administrador/usuarios",
        title: "Usuarios",
        isIcon: true,
        icon: "mdi-account-multiple-outline",
      },
      {
        path: "/administrador/encuentas",
        title: "Encuestas",
        isIcon: true,
        icon: "mdi-form-textbox",
      },
      {
        path: "/administrador/roles-permisos",
        title: "Roles y Permisos",
        isIcon: true,
        icon: "mdi-account-key-outline",
      },
    ],
    showAnimationTitle: true,
  }),
  watch: {
    $route(to, from) {
      console.log(to, from);
      this.showAnimationTitle = false;
      setTimeout(() => {
        this.showAnimationTitle = true;
      }, 1);
    },
  },
  mounted() {},
  methods: {
    async logout() {
      try {
        let url = process.env.VUE_APP_API_URL;

        // Obtener el token de localStorage
        const token = this.$store.state.token;

        console.log(token);

        const response = await axios.post(
          url + "/logout",
          {},
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );

        if (response) {
          // Limpiar el token de localStorage y redirigir al usuario a la página de inicio
          localStorage.removeItem("token");
          this.$router.push({ path: "/" }); // Redirigir a la página de inicio
        }
      } catch (error) {
        console.error("Error logging out:", error);
      }
    },
  },
};
</script>

<style>
body {
  font-family: "Be Vietnam Pro", sans-serif !important;
}

* {
  font-family: "Be Vietnam Pro", sans-serif !important;
}

.list-remove-overlay .v-list-item__overlay {
  display: none;
}

.badge-avatar .v-badge__badge::after {
  box-shadow: 0px 0px 6px 0px #0000004a;
  border-width: 3px;
}

.custom-line-height {
  line-height: 1.2 !important;
}

.navigation-border-1::after {
  content: "";
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  width: 1px;
  background: linear-gradient(
    to bottom,
    rgba(219, 219, 219, 0) 0%,
    #9e9e9e 50%,
    rgba(219, 219, 219, 0) 100%
  );
}

.overflow-visible-app-bar .v-toolbar__content {
  overflow: visible !important;
}

.Vue-Toastification__toast--success {
  background-color: #00a551 !important;
  color: #fff;
}
</style>
