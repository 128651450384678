export default [
    {
        path: '/administrador/configuracion/informacion-personal',
        name: 'ProfileAdministrator',
        component: () => import('@/views/user/entity/InfoPersonal.vue'),
        meta: { role: 'account', title: 'Mi perfil', role_id: [3], requiresAuth: true, subTitle: 'Gestiona tu información personal y configura tus preferencias.', }
    },
    {
        path: '/entidad/configuracion/informacion-personal',
        name: 'ProfileEntity',
        component: () => import('@/views/user/entity/InfoPersonal.vue'),
        meta: { role: 'account', title: 'Mi perfil', role_id: [4], requiresAuth: true, subTitle: 'Gestiona tu información personal y configura tus preferencias.', }
    },
    {
        path: '/entidad/configuracion/foto-perfil',
        name: 'PhotoProfileEntity',
        component: () => import('@/views/user/PhotoProfile.vue'),
        meta: { role: 'account', title: 'Mi perfil', role_id: [4], requiresAuth: true, subTitle: 'Gestiona tu información personal y configura tus preferencias.', }
    },
    {
        path: '/entidad/configuracion/informacion-contacto',
        name: 'ContactInfoEntity',
        component: () => import('@/views/user/entity/ContactInfo.vue'),
        meta: { role: 'account', title: 'Mi perfil', role_id: [4], requiresAuth: true, subTitle: 'Gestiona tu información personal y configura tus preferencias.', }
    },
    {
        path: '/entidad/configuracion/cambiar-contrasena',
        name: 'ChangePasswordEntity',
        component: () => import('@/views/user/ChangePassword.vue'),
        meta: { role: 'account', title: 'Mi perfil', role_id: [4], requiresAuth: true, subTitle: 'Gestiona tu información personal y configura tus preferencias.', }
    }

];