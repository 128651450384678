export default [
    {
        path: '/empresario/configuracion/informacion-personal',
        name: 'ProfileEntrepreneur',
        component: () => import('@/views/user/InfoPersonal.vue'),
        meta: { role: 'account', title: 'Mi perfil', role_id: [1, 2, 3, 4, 5, 6, 7, 8], requiresAuth: true, subTitle: 'Gestiona tu información personal y configura tus preferencias.', }
    },
    {
        path: '/empresario/configuracion/foto-perfil',
        name: 'PhotoProfileEntrepreneur',
        component: () => import('@/views/user/PhotoProfile.vue'),
        meta: { role: 'account', title: 'Mi perfil', role_id: [1, 2, 3, 4, 5, 6, 7, 8], requiresAuth: true, subTitle: 'Gestiona tu información personal y configura tus preferencias.', }
    },
    {
        path: '/empresario/configuracion/informacion-contacto',
        name: 'ContactInfoEntrepreneur',
        component: () => import('@/views/user/ContactInfo.vue'),
        meta: { role: 'account', title: 'Mi perfil', role_id: [1, 2, 3, 4, 5, 6, 7, 8], requiresAuth: true, subTitle: 'Gestiona tu información personal y configura tus preferencias.', }
    },
    {
        path: '/empresario/configuracion/cambiar-contrasena',
        name: 'ChangePasswordEntrepreneur',
        component: () => import('@/views/user/ChangePassword.vue'),
        meta: { role: 'account', title: 'Mi perfil', role_id: [1, 2, 3, 4, 5, 6, 7, 8], requiresAuth: true, subTitle: 'Gestiona tu información personal y configura tus preferencias.', }
    },
    {
        path: '/empresario/configuracion/sitio-turistico-registrado',
        name: 'RegisteredTouristSiteEntrepreneur',
        component: () => import('@/views/user/RegisteredTouristSite.vue'),
        meta: { role: 'account', title: 'Mi perfil', role_id: [1, 2, 3, 4, 5, 6, 7, 8], requiresAuth: true, subTitle: 'Gestiona tu información personal y configura tus preferencias.', }
    },
    {
        path: '/empresario/configuracion/registro-actividades-economicas',
        name: 'RegistrationEconomicActivitiesEntrepreneur',
        component: () => import('@/views/user/RegistrationEconomicActivities.vue'),
        meta: { role: 'account', title: 'Mi perfil', role_id: [1, 2, 3, 4, 5, 6, 7, 8], requiresAuth: true, subTitle: 'Gestiona tu información personal y configura tus preferencias.', }
    },
    {
        path: '/empresario/configuracion/company-details',
        name: 'CompanyDetailsEntrepreneur',
        component: () => import('@/views/user/CompanyDetails.vue'),
        meta: { role: 'account', title: 'Mi perfil', role_id: [1, 2, 3, 4, 5, 6, 7, 8], requiresAuth: true, subTitle: 'Gestiona tu información personal y configura tus preferencias.', }
    },
    {
        path: '/empresario/configuracion/documentos-establecimiento',
        name: 'EstablishmentDocumentsEntrepreneur',
        component: () => import('@/views/user/EstablishmentDocuments.vue'),
        meta: { role: 'account', title: 'Mi perfil', role_id: [1, 2, 3, 4, 5, 6, 7, 8], requiresAuth: true, subTitle: 'Gestiona tu información personal y configura tus preferencias.', }
    },
    {
        path: '/empresario/configuracion/actividad-comercial',
        name: 'CommercialActivityEntrepreneur',
        component: () => import('@/views/user/CommercialActivity.vue'),
        meta: { role: 'account', title: 'Mi perfil', role_id: [1, 2, 3, 4, 5, 6, 7, 8], requiresAuth: true, subTitle: 'Gestiona tu información personal y configura tus preferencias.', }
    },


];