<template>
  <v-row class="fill-height">
    <v-col cols="12" md="12">
      <div class="d-flex pt-3">
        <v-img height="60" :src="require('@/assets/img/logo-guaviare-color.png')"></v-img>
        <v-spacer></v-spacer>
      </div>

      <div class="d-flex justify-center align-center mb-16 pb-6" style="height: 100%">
        <v-form ref="formlogin">
          <v-card elevation="0" max-width="410" class="mx-auto">
            <!-- <v-img height="120" :src="require('@/assets/img/logo-guaviare-large.png')"></v-img> -->
            <v-card-title class="">
              <h4
                class="text-left text-h4 font-weight-bold"
                style="white-space: normal; word-break: auto-phrase"
              >
                Observatorio de Turismo del Guaviare
              </h4>
            </v-card-title>
            <v-card-subtitle class="text-wrap pb-9">
              Ingresa tu correo y contraseña para que sigamos explorando juntos.
            </v-card-subtitle>
            <v-card-text>
              <v-responsive class="mx-auto mb-2">
                <span class="font-weight-medium"> Correo electrónico </span>
                <v-text-field
                  :rules="[validations.required, validations.emailFormat]"
                  rounded="lg"
                  color="secondary"
                  @keydown.enter="login"
                  required
                  name="email"
                  autocomplete="new-password"
                  variant="outlined"
                  density="compact"
                  type="input"
                  v-model="email"
                ></v-text-field>
              </v-responsive>
              <v-responsive class="mx-auto">
                <span class="font-weight-medium"> Contraseña </span>
                <v-text-field
                  :rules="[validations.required]"
                  rounded="lg"
                  color="secondary"
                  @keydown.enter="login"
                  required
                  autocomplete="new-password"
                  v-model="password"
                  variant="outlined"
                  density="compact"
                  :type="visible ? 'text' : 'password'"
                >
                  <template v-slot:append-inner="">
                    <v-icon
                      style="opacity: 1"
                      color="primary"
                      @click="visible = !visible"
                    >
                      {{ visible ? "mdi-eye-off" : "mdi-eye" }}
                    </v-icon>
                  </template>
                </v-text-field>
              </v-responsive>
              <div class="d-flex">
                <!-- <v-checkbox v-model="remerberMe" color="secondary" hide-details
                                        label="Recuérdame"></v-checkbox> -->
                <v-spacer></v-spacer>
                <router-link
                  class="text-decoration-none text-primary font-weight-medium d-flex align-self-center"
                  to="/recuperar-cotrasena"
                  >¿No recuerdas tu contraseña?</router-link
                >
              </div>
              <v-responsive class="mx-auto mt-5 pt-5">
                <v-hover>
                  <template v-slot:default="{ isHovering, props }">
                    <v-btn
                      v-bind="props"
                      :disabled="loadingForm"
                      rounded="lg"
                      :loading="loadingForm"
                      @click="login"
                      color="secondary"
                      block
                      :variant="isHovering ? 'outlined' : 'flat'"
                      class="text-capitalize"
                    >
                      <span
                        v-if="isHovering"
                        :class="{ 'animate__animated  animate__slideInLeft': isHovering }"
                      >
                        Ingresar Ahora
                      </span>
                      <span
                        class="animate__animated animate__slideInRight"
                        :class="{ 'd-none': isHovering }"
                      >
                        Ingresar Ahora
                      </span>
                      <div class="area">
                        <ul class="circles">
                          <li :class="{ 'bg-secondary opacity-40': isHovering }"></li>
                          <li :class="{ 'bg-secondary opacity-40': isHovering }"></li>
                          <li :class="{ 'bg-secondary opacity-40': isHovering }"></li>
                          <li :class="{ 'bg-secondary opacity-40': isHovering }"></li>
                          <li :class="{ 'bg-secondary opacity-40': isHovering }"></li>
                          <li :class="{ 'bg-secondary opacity-40': isHovering }"></li>
                          <li :class="{ 'bg-secondary opacity-40': isHovering }"></li>
                          <li :class="{ 'bg-secondary opacity-40': isHovering }"></li>
                          <li :class="{ 'bg-secondary opacity-40': isHovering }"></li>
                          <li :class="{ 'bg-secondary opacity-40': isHovering }"></li>
                        </ul>
                      </div>
                    </v-btn>
                  </template>
                </v-hover>
              </v-responsive>
              <!-- <div class="mx-auto text-subtitle text-grey text-center py-5 or-login-text">
                                        O ingresa con
                                    </div> -->
              <v-responsive class="mx-auto py-5 mt-4 text-center">
                <span>
                  ¿Aún no tienes cuenta?

                  <router-link
                    to="/registro"
                    class="text-decoration-none text-primary font-weight-medium"
                    >¡Únete al Observatorio aquí!</router-link
                  >
                </span>
              </v-responsive>
            </v-card-text>
          </v-card>
        </v-form>
      </div>
    </v-col>
  </v-row>
</template>

<script>
import validationFields from "./functions/validationFields";
import axios from "axios";
import { useToast } from "vue-toastification";
export default {
  mixins: [validationFields],
  name: "LoginView",
  setup() {
    // Get toast interface
  },
  data() {
    return {
      email: null,
      password: null,
      remerberMe: false,
      loadingForm: false,
      visible: false,
    };
  },
  methods: {
    // async login() {

    //     //validar formulario formlogin
    //     const { valid } = await this.$refs.formlogin.validate()

    //     if (valid) {

    //         this.loadingForm = true;
    //         setTimeout(() => {
    //             this.$router.push('/administrador');
    //         }, 2000);

    //     }

    // },
    async login() {
      const { valid } = await this.$refs.formlogin.validate();

      if (valid) {
        const toast = useToast();
        try {
          this.loadingForm = true;
          let url = process.env.VUE_APP_API_URL;

          const response = await axios.post(url + "/login", {
            email: this.email,
            password: this.password,
          });

          if (response) {
            this.loadingForm = false;
          }
          const { access_token, user } = response.data; //
          localStorage.setItem("token", access_token);
          this.redirectBasedOnRole(user); //
        } catch (error) {
          this.loadingForm = false;
          if (
            error &&
            error.response &&
            error.response.status &&
            error.response.status == 401
          ) {
            toast.error("Credenciales inválidas. verifica tu email y contraseña.", {
              timeout: 5000,
              hideProgressBar: true,
            });
          } else {
            toast.error("Error al iniciar sesión", {
              timeout: 5000,
              hideProgressBar: true,
            });
          }
          console.error("Error logging in:", error);
          // Handle error
        }
      }
    },
    redirectBasedOnRole(user) {
      if (user.roles) {
        const role = user.roles[0];

        switch (role) {
          case 1: // Atractivo
            this.$router.push("/empresario");
            break;
          case 2: // Alojamiento
            this.$router.push("/empresario");
            break;
          case 3: // Administrador
            this.$router.push("/administrador");
            break;
          case 4: // Policía de Turismo
            this.$router.push("/entidad");
            break;
          case 5: // ICANH
            this.$router.push("/entidad");
            break;
          case 6: // Agencia de viaje
            this.$router.push("/empresario");
            break;
          case 7: // Agencia de viajes + alojamiento
            this.$router.push("/empresario");
            break;
          case 8: // Atractivo + Alojamiento
            this.$router.push("/empresario");
            break;

          default:
            console.error("Unknown role");
          // Handle unknown role
        }
      }
    },
  },
};
</script>

<style>
.slider-login {
  height: 100%;
  /*background-image: url('../assets/img/forest-animals-nature-branch-snake-green-14903-wallhere.com.jpg');*/
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  position: relative;
  overflow: hidden;
  padding: 0;
}

.slider-login::before {
  content: "";
  position: absolute;
  z-index: 2;
  border-left: 130px solid #ffffff;
  border-top: 20vh solid transparent;
  border-bottom: 80vh solid transparent;
}

.app-carousel::before {
  background-color: rgb(0 0 0 / 15%);
  /* background-color: rgb(0 165 81 / 18%); */
  content: "";
  height: 100%;
  width: 100%;
  position: absolute;
  z-index: 1;
  backdrop-filter: blur(2px);
}

.or-login-text {
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  position: relative;
}

.or-login-text::after {
  content: "";
  flex-grow: 1;
  height: 1px;
  background-color: #b0bec5;
  /* Ajusta el color de la línea según sea necesario */
  margin: 0 10px;
  /* Ajusta el margen según sea necesario */
  position: relative;
  top: 50%;
  transform: translateY(-50%);
}

.or-login-text::before {
  content: "";
  flex-grow: 1;
  height: 1px;
  background-color: #b0bec5;
  /* Ajusta el color de la línea según sea necesario */
  margin: 0 10px;
  /* Ajusta el margen según sea necesario */
  position: relative;
  top: 50%;
  transform: translateY(-50%);
}
</style>
