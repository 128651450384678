<template>
  <v-row class="fill-height">
    <v-col cols="12" md="12">
      <div class="d-flex pt-3">
        <v-img height="60" :src="require('@/assets/img/logo-guaviare-color.png')"></v-img>
        <v-spacer></v-spacer>
      </div>

      <v-card-title class="mt-8 px-5">
        <h4
          class="text-left text-h4 font-weight-bold"
          style="white-space: normal; word-break: auto-phrase"
        >
          ¿Quieres ser hacer parte activa del Observatorio?
        </h4>
      </v-card-title>
      <v-card-subtitle class="text-wrap pb-9 px-5">
        ¡Regístrate para activar tu usuario y construyamos juntos los datos para conocer
        mejor cómo se está moviendo el turismo en el Guaviare!
      </v-card-subtitle>

      <div class="d-flex justify-center mb-16 pb-6 mt-5" style="height: 100%">
        <v-form ref="formregisterentity">
          <v-card elevation="0" max-width="410" class="mx-auto">
            <!-- <v-img height="120" :src="require('@/assets/img/logo-guaviare-large.png')"></v-img> -->
            <v-card-title class="">
              <h4
                class="text-left text-h5 font-weight-bold"
                style="white-space: normal; word-break: auto-phrase"
              >
                Registro de Entidades
              </h4>
            </v-card-title>
            <v-card-subtitle class="text-wrap pb-9">
              Completa los detalles de tu entidad para registrarte.
            </v-card-subtitle>

            <v-card-text>
              <v-row>
                <v-col sm="12" md="12" cols="12" class="px-1 py-1">
                  <span class="text-body-1 font-weight-bold">Información General</span>
                </v-col>
                <v-col sm="12" md="12" cols="12" class="px-1 py-1">
                  <span class="font-weight-medium">Nombre de la Entidad</span>
                  <v-text-field
                    :rules="[validations.required, validations.maxLength(255)]"
                    variant="outlined"
                    density="compact"
                    rounded="lg"
                    v-model="form.form_entity_name_entity"
                  ></v-text-field>
                </v-col>
                <v-col sm="12" md="12" cols="12" class="px-1 py-1">
                  <span class="font-weight-medium">Nit</span>
                  <v-text-field
                    variant="outlined"
                    density="compact"
                    rounded="lg"
                    v-model="form.form_entity_nit"
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row class="mt-0">
                <v-col sm="12" md="12" cols="12" class="px-1 py-1">
                  <span class="text-body-1 font-weight-bold"
                    >Datos de Contacto del Funcionario</span
                  >
                </v-col>
                <v-col sm="12" md="12" cols="12" class="px-1 py-1">
                  <span class="font-weight-medium">Nombre Completo</span>
                  <v-text-field
                    :rules="[validations.required, validations.maxLength(255)]"
                    variant="outlined"
                    density="compact"
                    rounded="lg"
                    v-model="form.form_entity_full_name"
                  ></v-text-field>
                </v-col>
                <v-col sm="12" md="12" cols="12" class="px-1 py-1">
                  <span class="font-weight-medium">Cargo</span>
                  <v-text-field
                    variant="outlined"
                    density="compact"
                    rounded="lg"
                    :rules="[validations.required, validations.maxLength(255)]"
                    v-model="form.form_entity_position"
                  ></v-text-field>
                </v-col>
                <v-col sm="12" md="12" cols="12" class="px-1 py-1">
                  <span class="font-weight-medium">Teléfono</span>
                  <v-text-field
                    :rules="[validations.required, validations.maxLength(255)]"
                    variant="outlined"
                    density="compact"
                    rounded="lg"
                    v-model="form.form_entity_phone"
                  ></v-text-field>
                </v-col>
                <v-col sm="12" md="12" cols="12" class="px-1 py-1">
                  <span class="font-weight-medium">Correo Electrónico </span>
                  <v-text-field
                    :rules="[
                      validations.required,
                      validations.maxLength(255),
                      validations.emailFormat,
                    ]"
                    variant="outlined"
                    density="compact"
                    rounded="lg"
                    v-model="form.form_entity_email"
                  ></v-text-field>
                </v-col>
                <v-col sm="12" md="12" cols="12" class="px-1 py-1">
                  <v-checkbox
                    v-model="form.form_entity_accept_terms"
                    true-value="Si"
                    :false-value="null"
                    :rules="[validations.required]"
                  >
                    <template v-slot:label>
                      <div>
                        Acepto los
                        <v-tooltip location="bottom">
                          <template v-slot:activator="{ props }">
                            <a
                              href="https://www.observatorioturistico.com.co/#"
                              target="_blank"
                              v-bind="props"
                              class="text-decoration-none text-primary"
                              @click.stop
                            >
                              Términos y Condiciones
                            </a>
                          </template>
                          <span
                            >Consulta los términos de uso y nuestra política de
                            privacidad</span
                          >
                        </v-tooltip>
                        y autorizo el tratamiento de mis datos personales de acuerdo con
                        lo establecido en la
                        <v-tooltip location="bottom">
                          <template v-slot:activator="{ props }">
                            <a
                              href="https://www.observatorioturistico.com.co/#"
                              target="_blank"
                              v-bind="props"
                              class="text-decoration-none text-primary"
                              @click.stop
                            >
                              Política de Privacidad
                            </a>
                          </template>
                          <span
                            >Lee nuestra política de privacidad y cómo protegemos tu
                            información</span
                          > </v-tooltip
                        >.
                      </div>
                    </template>
                  </v-checkbox>
                </v-col>
                <!-- <v-col
                  sm="12"
                  md="12"
                  cols="12"
                  class="px-1 py-1"
                >
                  <span class="font-weight-medium">Contraseña </span>
                  <v-text-field
                    :rules="[
                      validations.required,
                      validations.maxLength(255),
                      validations.securePassword,
                    ]"
                    autocomplete="new-password"
                    :type="visible ? 'text' : 'password'"
                    rounded="lg"
                    density="compact"
                    variant="outlined"
                  >
                    <template v-slot:append-inner="">
                      <v-icon
                        style="opacity: 1"
                        color="primary"
                        @click="visible = !visible"
                      >
                        {{ visible ? "mdi-eye-off" : "mdi-eye" }}
                      </v-icon>
                    </template>
                  </v-text-field>
                </v-col> -->
              </v-row>

              <v-responsive class="mx-auto mt-5 pt-5">
                <v-hover>
                  <template v-slot:default="{ isHovering, props }">
                    <v-btn
                      v-bind="props"
                      :disabled="loadingForm"
                      rounded="lg"
                      :loading="loadingForm"
                      @click="registerEntity"
                      color="primary"
                      block
                      :variant="isHovering ? 'outlined' : 'flat'"
                      class="text-capitalize"
                    >
                      <span
                        v-if="isHovering"
                        :class="{ 'animate__animated  animate__slideInLeft': isHovering }"
                      >
                        Únete Ahora
                      </span>
                      <span
                        class="animate__animated animate__slideInRight"
                        :class="{ 'd-none': isHovering }"
                      >
                        Únete Ahora
                      </span>
                      <div class="area">
                        <ul class="circles">
                          <li :class="{ 'bg-primary opacity-40': isHovering }"></li>
                          <li :class="{ 'bg-primary opacity-40': isHovering }"></li>
                          <li :class="{ 'bg-primary opacity-40': isHovering }"></li>
                          <li :class="{ 'bg-primary opacity-40': isHovering }"></li>
                          <li :class="{ 'bg-primary opacity-40': isHovering }"></li>
                          <li :class="{ 'bg-primary opacity-40': isHovering }"></li>
                          <li :class="{ 'bg-primary opacity-40': isHovering }"></li>
                          <li :class="{ 'bg-primary opacity-40': isHovering }"></li>
                          <li :class="{ 'bg-primary opacity-40': isHovering }"></li>
                          <li :class="{ 'bg-primary opacity-40': isHovering }"></li>
                        </ul>
                      </div>
                    </v-btn>
                  </template>
                </v-hover>
              </v-responsive>

              <v-responsive class="mx-auto py-5 mt-4 text-center">
                <span>
                  ¿Ya tienes cuenta?

                  <router-link
                    to="/login"
                    class="text-decoration-none text-primary font-weight-medium"
                  >
                    Inicia sesión aquí</router-link
                  >
                </span>
              </v-responsive>
            </v-card-text>
          </v-card>
        </v-form>
      </div>
    </v-col>
  </v-row>
  <DialogResponse
      @cancel="cancelDialogResponse"
      v-if="dialogResponse"
      :dialog="dialogResponse"
    ></DialogResponse>
</template>

<script>
import validationFields from "./functions/validationFields";
import ErrorLayoutComponent from "@/components/error-request/ErrorLayoutComponent.vue";
import axios from "axios";
import DialogResponse from "@/components/dialog/DialogResponse.vue";

export default {
  name: "RegisterEntityView",
  mixins: [validationFields],
  components: {
    DialogResponse,
  },
  setup() {
    // Get toast interface
  },
  data() {
    return {
      dialogResponse: null,
      typePerson: null,
      loadingForm: false,
      visible: false,
      form: {
        form_entity_name_entity: null,
        form_entity_nit: null,
        form_entity_full_name: null,
        form_entity_position: null,
        form_entity_phone: null,
        form_entity_email: null,
        form_entity_accept_terms: null,
      },
    };
  },
  mounted() {
    this.getRelationsQuestion();
  },
  methods: {
    async registerEntity() {
      const { valid } = await this.$refs.formregisterentity.validate();

      if (valid) {
        let url = process.env.VUE_APP_API_URL;

        axios
          .post(url + "/form-responses/store-entity", {
            form: this.form,
            form_id: 14,
            form_relations_question: this.form_relations_question,
          })
          .then((response) => {
            this.loadingPageOverlay = false;

            this.dialogResponse = {
              title: "¡Información almacenada correctamente!",
              message:
                "Gracias por tomarte el tiempo de completar el formulario de registro. Puedes estar seguro de que tus datos han sido guardados de manera segura.",
              type: "success",
              color: "secondary",
              icon: "mdi-check",
              show: true,
              redirect: false,
            };
            console.log(response);
            //resetear todo el componente
            this.resetFormFields();
            this.stepFormServiceOrder = 1;
          })
          .catch((error) => {
            this.loadingPageOverlay = false;
            if (
              error &&
              error.response &&
              error.response.status &&
              error.response.status == 422
            ) {
              //ERROR RETORNADO POR LAS VALIDACIONES
              this.toast.error({
                component: ErrorLayoutComponent,
                props: {
                  errorMessage: error.response.data,
                },
                timeout: 8000,
              });
            } else {
              this.dialogResponse = {
                title: "Error al enviar el formulario",
                message:
                  "Ocurrió un problema al intentar guardar tus respuestas. Por favor, revisa los campos y vuelve a intentarlo. Si el problema persiste, contacta al soporte técnico.",
                type: "success",
                color: "red-app",
                icon: "mdi-cloud-alert",
                show: true,
                redirect: false,
              };
            }

            console.log(error);
          });
      }
    },
    resetFormFields(){
      this.form = {
        form_entity_name_entity: null,
        form_entity_nit: null,
        form_entity_full_name: null,
        form_entity_position: null,
        form_entity_phone: null,
        form_entity_email: null,
        form_entity_accept_terms: null,
      };
    },
    getRelationsQuestion() {
      let url = process.env.VUE_APP_API_URL;
      axios
        .get(url + "/forms/get-relations-questions/14")
        .then((response) => {
          this.form_relations_question = response.data;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    async cancelDialogResponse() {
      this.dialogResponse = null;
    },
  },
};
</script>

<style>
.select-content .v-slide-group__content {
  justify-content: center;
}
</style>
